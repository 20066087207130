import { Component, OnInit } from '@angular/core';
import { GService } from 'src/app/services/g.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.page.html',
  styleUrls: ['./connect.page.scss'],
})
export class ConnectPage implements OnInit {

  constructor(private g: GService) { }

  ngOnInit() {
    this.g.hiddenHome = true;
  }


  retry() {
    document.location.href = "/?code=" + this.g.localPrefs.docCode;
  }
}
