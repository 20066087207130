import { Injectable } from "@angular/core";
import { DocumentData } from "angularfire2/firestore";
import { Observable } from "rxjs";
import { FirebaseService } from "./firebase.service";
import { GService } from "./g.service";

@Injectable({
	providedIn: "root",
})
export class SearchService {
	// select item on cat -> side.page and prod -> side.page components
	selectedItem = "";
	// for search on prod -> side.page and prod -> main.page components
	searchTerm = "";
	lastSearch = "";
	productsList: DocumentData[] = [];
	displayedDoc: DocumentData[] = [];
	actualVisible;
	addProd: boolean = false;
	prodsIndex: number = 0;

	constructor(private g: GService, private fire: FirebaseService) { }

	cancelFilter() {
		this.searchTerm = '';
		this.actualVisible = '';
		this.addProd = false;
		this.displayedDoc = [];
		this.selectedItem = '';
		this.prodsIndex = 0;
	}

	resetFilter() {
		this.searchTerm = '';
		this.actualVisible = '';
		this.addProd = false;
		this.displayedDoc = [];
		this.selectedItem = '';
		this.prodsIndex = 0;
	}

	searchProducts() {

		if (this.lastSearch === '' && this.searchTerm === '') {
			//ricerca non attiva
			console.log("ricerca non attiva. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
		} else if (this.lastSearch !== '' && this.searchTerm !== '') {
			if(this.lastSearch !== this.searchTerm) {
				//ricerca già attivata, inserito nuovo carattere nella ricerca
				console.log("ricerca già attivata. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
				this.displayedDoc = [];
				this.prodsIndex = 0;
			} else {
				//ricerca avviata, scroll
				console.log("ricerca avviata, scroll. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
			}
		} else if (this.lastSearch !== '' && this.searchTerm === '') {
			//ricerca cancellata
			console.log("ricerca cancellata. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
			this.displayedDoc = [];
			this.prodsIndex = 0;
		} else if (this.lastSearch === '' && this.searchTerm !== '') {
			//ricerca iniziata
			console.log("ricerca iniziata. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
			this.displayedDoc = [];
			this.prodsIndex = 0;
		}
		console.log('START AT: ', this.prodsIndex);
		this.fire.searchProds(this.searchTerm, this.prodsIndex).subscribe({
			next: (res: any) => {
				this.prodsIndex = res.index;
				res.products.forEach(async p => {
					let prodData: DocumentData;
					///SOCIO
					if (p.fromSocio && p.fromSocio === true) {
						prodData = this.fire.getProductDataSByID(p.id);
						if (prodData.immagine) {
							prodData.immaginiProdotto = { img: '', slides: [], available: false };
							this.fire.readImage(prodData.immagine.id).then((resImg: string) => {
								prodData.immaginiProdotto.img = resImg;
								prodData.immaginiProdotto.slides.push(resImg);
								prodData.immaginiProdotto.available = true;
							}, (err) => {
							});
						} else {
							prodData.immaginiProdotto = { img: '', slides: [], available: false };
						}
						this.fire.readImageArray(prodData.slides).then((resImgs: string[]) => {
							for (let res of resImgs) {
								prodData.immaginiProdotto.slides.push(res);
							}
						}, (err) => {
						});
						this.displayedDoc.push(prodData);
						this.lastSearch = this.searchTerm;
					} else {
						//SEDE
						this.fire.getProductDataByID(p.id).subscribe(
							data => {
								prodData = data;
								if (prodData.immagine) {
									prodData.immaginiProdotto = { img: '', slides: [], available: false };
									this.fire.readImage(prodData.immagine.id).then((resImg: string) => {
										prodData.immaginiProdotto.img = resImg;
										prodData.immaginiProdotto.slides.push(resImg);
									}, (err) => {
									});
								} else if (!prodData.immagine && typeof prodData.immaginiProdotto === 'undefined') {
									prodData.immaginiProdotto = { img: '', slides: [], available: false };
									const url = 'https://cmcode.ocacloud.it/upload/images/' + prodData.codice_code + '.jpg';
									prodData.immaginiProdotto.img = url;
								} else {
									prodData.immaginiProdotto = { img: '', slides: [], available: false };
								}
								this.fire.readImageArray(prodData.slides).then((resImgs: string[]) => {
									for (let res of resImgs) {
										prodData.immaginiProdotto.slides.push(res);
									}
								}, (err) => {
								});
								this.lastSearch = this.searchTerm;
								this.displayedDoc.push(prodData);
							},
							err => {
								console.log(err)
							}
						);
					}
				});
			},
			error: (err: Error) => {
				console.error("error error", err);
			}
		});
	}

	// setFilteredItems() {
	// 	return new Observable((observer) => {
	// 		if (this.displayedDoc.length > 0) {
	// 			this.actualVisible = this.displayedDoc[this.displayedDoc.length - 1].titolo[this.g.localPrefs.language];
	// 		} else {
	// 			this.actualVisible = '';
	// 		}
	// 		this.fire.searchProduct(this.searchTerm.toUpperCase(), this.actualVisible).subscribe({
	// 			next: (prods: DocumentData[]) => {
	// 				if (!this.addProd) {
	// 					this.displayedDoc = prods;
	// 					console.log(this.fire.productDataS);
	// 					this.fire.productDataS.forEach(pS => {
	// 						this.displayedDoc.push(pS.data());
	// 					});
	// 					this.displayedDoc = this.displayedDoc.sort((a, b) => (a.titolo[this.g.localPrefs.language].toUpperCase() > b.titolo[this.g.localPrefs.language].toUpperCase()) ? 1 : ((b.titolo[this.g.localPrefs.language].toUpperCase() > a.titolo[this.g.localPrefs.language].toUpperCase()) ? -1 : 0));
	// 					this.displayedDoc = this.removeDup(this.displayedDoc);
	// 				} else {
	// 					prods.forEach(element => {
	// 						this.displayedDoc.push(element);
	// 					});
	// 					this.displayedDoc.sort((a, b) => (a.titolo[this.g.localPrefs.language].toUpperCase() > b.titolo[this.g.localPrefs.language].toUpperCase()) ? 1 : ((b.titolo[this.g.localPrefs.language].toUpperCase() > a.titolo[this.g.localPrefs.language].toUpperCase()) ? -1 : 0))
	// 					this.displayedDoc = this.removeDup(this.displayedDoc);
	// 					this.addProd = false;
	// 				}
	// 				for (const p of this.displayedDoc) {
	// 					if (p.id) { // il prodotto è inserito da sede
	// 						if (p.immagine) {
	// 							p.immaginiProdotto = { img: '', slides: [], available: false };
	// 							this.fire.readImage(p.immagine.id).then((resImg: string) => {
	// 								p.immaginiProdotto.img = resImg;
	// 								p.immaginiProdotto.slides.push(resImg);
	// 							}, (err) => {
	// 								observer.error(err);
	// 								observer.complete();
	// 							});
	// 						} else if (!p.immagine && typeof p.immaginiProdotto === 'undefined') {
	// 							p.immaginiProdotto = { img: '', slides: [], available: false };
	// 							const url = 'https://cmcode.ocacloud.it/upload/images/' + p.codice_code + '.jpg';
	// 							p.immaginiProdotto.img = url;
	// 						} else {
	// 							p.immaginiProdotto = { img: '', slides: [], available: false };
	// 						}
	// 						this.fire.readImageArray(p.slides).then((resImgs: string[]) => {
	// 							for (let res of resImgs) {
	// 								p.immaginiProdotto.slides.push(res);
	// 							}
	// 						}, (err) => {
	// 							observer.error(err);
	// 							observer.complete();
	// 						});
	// 					} else { // il prodotto è inserito da socio
	// 						if (p.immagine) {
	// 							p.immaginiProdotto = { img: '', slides: [], available: false };
	// 							this.fire.readImage(p.immagine.id).then((resImg: string) => {
	// 								p.immaginiProdotto.img = resImg;
	// 								p.immaginiProdotto.slides.push(resImg);
	// 								p.immaginiProdotto.available = true;
	// 							}, (err) => {
	// 								observer.error(err);
	// 								observer.complete();
	// 							});
	// 						} else {
	// 							p.immaginiProdotto = { img: '', slides: [], available: false };
	// 						}
	// 						this.fire.readImageArray(p.slides).then((resImgs: string[]) => {
	// 							for (let res of resImgs) {
	// 								p.immaginiProdotto.slides.push(res);
	// 							}
	// 						}, (err) => {

	// 						});
	// 					}
	// 				}
	// 				observer.next(true);
	// 				observer.complete();
	// 			},
	// 			error: (err: Error) => {
	// 				observer.error(err);
	// 				observer.complete();
	// 			}
	// 		});
	// 	});

	// 	// GIA COMMENTATO
	// 	// -this.displayedDoc = this.productsList.filter((doc) => {
	// 	// -	return doc.descrizione[this.g.localPrefs.language].toLowerCase().includes(this.searchTerm.toLowerCase()) || 
	// 	// -	(doc.codice_prodotto[this.g.localPrefs.language] + doc.digit[this.g.localPrefs.language]).toLowerCase().includes(this.searchTerm.toLowerCase());
	// 	// -});

	// }


	// setProducts() {
	// 	this.actualVisible = '';
	// 	this.fire.searchProduct(this.searchTerm.toUpperCase(), this.actualVisible).subscribe({
	// 		next: (prods: DocumentData[]) => {
	// 			this.displayedDoc = prods;
	// 			this.fire.productDataS.forEach(pS => {
	// 				this.displayedDoc.push(pS.data());
	// 			});
	// 			this.displayedDoc = this.displayedDoc.filter((doc) => {
	// 				return doc.titolo[this.g.localPrefs.language].toLowerCase().includes(this.searchTerm.toLowerCase()) ||
	// 					(doc.codice_code).toLowerCase().includes(this.searchTerm.toLowerCase());
	// 			});

	// 			this.displayedDoc.sort((a, b) => (a.titolo[this.g.localPrefs.language].toUpperCase() > b.titolo[this.g.localPrefs.language].toUpperCase()) ? 1 : ((b.titolo[this.g.localPrefs.language].toUpperCase() > a.titolo[this.g.localPrefs.language].toUpperCase()) ? -1 : 0))
	// 			this.displayedDoc = this.removeDup(this.displayedDoc);
	// 			for (const p of this.displayedDoc) {
	// 				if (p.id) { // il prodotto è inserito da sede
	// 					if (p.immagine) {
	// 						p.immaginiProdotto = { img: '', slides: [], available: false };
	// 						this.fire.readImage(p.immagine.id).then((resImg: string) => {
	// 							p.immaginiProdotto.img = resImg;
	// 							p.immaginiProdotto.slides.push(resImg);
	// 						}, (err) => {

	// 						});
	// 					} else if (!p.immagine && typeof p.immaginiProdotto === 'undefined') {
	// 						p.immaginiProdotto = { img: '', slides: [], available: false };
	// 						const url = 'https://cmcode.ocacloud.it/upload/images/' + p.codice_code + '.jpg';
	// 						p.immaginiProdotto.img = url;
	// 					} else {
	// 						p.immaginiProdotto = { img: '', slides: [], available: false };
	// 					}
	// 					this.fire.readImageArray(p.slides).then((resImgs: string[]) => {
	// 						for (let res of resImgs) {
	// 							p.immaginiProdotto.slides.push(res);
	// 						}
	// 					}, (err) => {

	// 					});
	// 				} else { // il prodotto è inserito da socio
	// 					if (p.immagine) {
	// 						p.immaginiProdotto = { img: '', slides: [], available: false };
	// 						this.fire.readImage(p.immagine.id).then((resImg: string) => {
	// 							p.immaginiProdotto.img = resImg;
	// 							p.immaginiProdotto.slides.push(resImg);
	// 						}, (err) => {

	// 						});
	// 					} else {
	// 						p.immaginiProdotto = { img: '', slides: [], available: false };
	// 					}
	// 					this.fire.readImageArray(p.slides).then((resImgs: string[]) => {
	// 						for (let res of resImgs) {
	// 							p.immaginiProdotto.slides.push(res);
	// 						}
	// 					}, (err) => {

	// 					});
	// 				}
	// 			}
	// 		},
	// 		error: (err: Error) => {
	// 			console.log(err);
	// 		}
	// 	});
	// }

	// removeDup(arr) {
	// 	var tmp = [];
	// 	for (var i = 0; i < arr.length; i++) {
	// 		if (tmp.indexOf(arr[i]) == -1) {
	// 			tmp.push(arr[i]);
	// 		}
	// 	}
	// 	return tmp;
	// }
}
