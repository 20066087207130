import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class StorageService {
	latestSync = "Non presente";
	downloadProgress = 0;
	downloadProgressChange: Subject<number> = new Subject<number>();
	downloadSize = 0;
	downloadedFiles = 0;
	prefs: { id: string; gram: string; form: string }[] = [];
	requested: { id: string; gram: string; form: string }[] = [];

	constructor() {
		this.downloadProgressChange.subscribe((value) => {
			this.downloadProgress = value;
		});
	}

	setLocalPrefs(localPrefs: any) {
		const sPrefs: string = JSON.stringify(localPrefs);
		localStorage.setItem("LocalPrefs", sPrefs);
	}

	getLocalPrefs() {
		const sPrefs: string = localStorage.getItem("LocalPrefs");
		if (sPrefs === null) return {};
		else return JSON.parse(sPrefs);
	}

	addProgress() {
		if (this.downloadProgress >= 1) {
			this.downloadProgress = 0;
		}
		this.downloadProgressChange.next((this.downloadProgress += 1 / this.downloadSize));
		this.downloadedFiles += 1;
	}

	resetProgress() {
		this.downloadProgressChange.next((this.downloadProgress = 0));
	}

	completeProgress() {
		this.downloadProgressChange.next((this.downloadProgress = 1));
	}

	setDownloadSize(size) {
		this.downloadSize = size;
	}

	setImageTags(tags: string[]) {
		localStorage.setItem("ImageTags", JSON.stringify(tags));
	}

	getImageTags() {
		return JSON.parse(localStorage.getItem("ImageTags"));
	}

	addImageTags(t) {
		const tags: string[] = JSON.parse(localStorage.getItem("ImageTags"));
		tags.push(t);
		localStorage.setItem("ImageTags", JSON.stringify(tags));
	}

	removeImageTags(t: string[]) {
		const tags: string[] = JSON.parse(localStorage.getItem("ImageTags"));
		t.forEach(el => {
			const index = tags.findIndex(p => p === el);
			tags.splice(index, 1);
		});
		localStorage.setItem("ImageTags", JSON.stringify(tags));
	}

	initPrefs() {
		const prefsArray = JSON.parse(localStorage.getItem("Prefs"));
		if (prefsArray !== undefined && prefsArray !== null) {
			this.prefs = prefsArray;
		} else {
			this.prefs = [];
		}
	}

	addPref(prodId: string, grammage: string, format: string) {
		this.prefs.push({ id: prodId, gram: grammage, form: format });
		localStorage.setItem("Prefs", JSON.stringify(this.prefs));
	}

	modifyPrefs(prodId: string, grammage: string, format: string) {
		if (this.prefs) {
			const index = this.prefs.findIndex((prod) => prod.id === prodId && prod.gram === grammage && prod.form === format);
			if (index > -1) {
				this.prefs.splice(index, 1);
			} else {
				this.prefs.push({ id: prodId, gram: grammage, form: format });
			}
		} else {
			this.prefs.push({ id: prodId, gram: grammage, form: format });
		}
		localStorage.setItem("Prefs", JSON.stringify(this.prefs));
	}

	removePref(index) {
		this.prefs.splice(index, 1);
		localStorage.setItem("Prefs", JSON.stringify(this.prefs));
	}

	removePrefByFields(prodId: string, grammage: string, format: string) {
		const index = this.prefs.findIndex((prod) => prod.id === prodId && prod.gram === grammage && prod.form === format);
		this.prefs.splice(index, 1);
		localStorage.setItem("Prefs", JSON.stringify(this.prefs));
	}

	isPref(prodId: string): boolean {
		if (this.prefs) {
			const index = this.prefs.findIndex((prod) => prod.id === prodId);
			if (index > -1) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	isPrefWithFields(prodId: string, grammage: string, format: string) {
		if (this.prefs) {
			const index = this.prefs.findIndex((prod) => prod.id === prodId && prod.gram === grammage && prod.form === format);
			if (index < 0) {
				return false;
			} else {
				if (this.prefs[index].gram === grammage && this.prefs[index].form === format) {
					return true;
				} else {
					return false;
				}
			}
		} else {
			return false;
		}
	}

	getPrefs() {
		return JSON.parse(localStorage.getItem("Prefs"));
	}

	getPrefFields(prodId) {
		const index = this.prefs.findIndex((prod) => prod.id === prodId);
		if (index > -1) {
			return this.prefs[index];
		}
	}

	modifyPrefGrammage(prodId, grammage) {
		const index = this.prefs.findIndex((prod) => prod.id === prodId);
		if (index > -1) {
			this.prefs[index].gram = grammage;
			this.prefs[index].form = "";
		}
		localStorage.setItem("Prefs", JSON.stringify(this.prefs));
	}

	modifyPrefFormat(prodId, format) {
		const index = this.prefs.findIndex((prod) => prod.id === prodId);
		if (index > -1) {
			this.prefs[index].form = format;
		}
		localStorage.setItem("Prefs", JSON.stringify(this.prefs));
	}

	initRequested() {
		const requestedArray = JSON.parse(localStorage.getItem("Requested"));
		if (requestedArray !== undefined && requestedArray !== null) {
			this.requested = requestedArray;
		} else {
			this.requested = [];
		}
	}

	addRequested(prodId: string, grammage: string, format: string) {
		this.requested.push({ id: prodId, gram: grammage, form: format });
		localStorage.setItem("Requested", JSON.stringify(this.requested));
	}

	modifyRequested(prodId: string, grammage: string, format: string) {
		if (this.requested) {
			const index = this.requested.findIndex((prod) => prod.id === prodId && prod.gram === grammage && prod.form === format);
			if (index > -1) {
				this.requested.splice(index, 1);
			} else {
				this.requested.push({ id: prodId, gram: grammage, form: format });
			}
		} else {
			this.requested.push({ id: prodId, gram: grammage, form: format });
		}
		localStorage.setItem("Requested", JSON.stringify(this.requested));
	}

	removeRequested(index) {
		this.requested.splice(index, 1);
		localStorage.setItem("Requested", JSON.stringify(this.requested));
	}

	removeRequestedByFields(prodId: string, grammage: string, format: string) {
		const index = this.requested.findIndex((prod) => prod.id === prodId && prod.gram === grammage && prod.form === format);
		this.requested.splice(index, 1);
		localStorage.setItem("Requested", JSON.stringify(this.requested));
	}

	isRequested(prodId: string): boolean {
		if (this.requested) {
			const index = this.requested.findIndex((prod) => prod.id === prodId);
			if (index > -1) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	isRequestedWithFields(prodId: string, grammage: string, format: string) {
		if (this.requested) {
			const index = this.requested.findIndex((prod) => prod.id === prodId && prod.gram === grammage && prod.form === format);
			if (index < 0) {
				return false;
			} else {
				if (this.requested[index].gram === grammage && this.requested[index].form === format) {
					return true;
				} else {
					return false;
				}
			}
		} else {
			return false;
		}
	}

	getRequested() {
		return JSON.parse(localStorage.getItem("Requested"));
	}

	getRequestedFields(prodId) {
		const index = this.requested.findIndex((prod) => prod.id === prodId);
		if (index > -1) {
			return this.requested[index];
		}
	}

	modifyRequestedGrammage(prodId, grammage) {
		const index = this.requested.findIndex((prod) => prod.id === prodId);
		if (index > -1) {
			this.requested[index].gram = grammage;
			this.requested[index].form = "";
		}
		localStorage.setItem("Requested", JSON.stringify(this.requested));
	}

	modifyRequestedFormat(prodId, format) {
		const index = this.requested.findIndex((prod) => prod.id === prodId);
		if (index > -1) {
			this.requested[index].form = format;
		}
		localStorage.setItem("Prefs", JSON.stringify(this.requested));
	}
}
