import { Injectable } from "@angular/core";
import { AngularFirestore, CollectionReference, DocumentData } from "angularfire2/firestore";
import { Observable } from "rxjs";
import { StorageService } from "./storage.service";
import { GService } from "./g.service";
import * as doc from "src/environments/doc.json";

const Typesense = require('typesense');

declare let navigator: any;
declare let window: any;

@Injectable({ providedIn: "root" })
export class FirebaseService { // COLLECTION REFERENCES
    classCollection: CollectionReference;
    productCollection: CollectionReference;
    localCollection: CollectionReference;
    mediaCollection: CollectionReference;
    settingCollection: CollectionReference;
    productS: CollectionReference;
    typesenseCollection: any;

    // COLLECTIONS' DOCUMENT DATA
    categoryData: DocumentData[] = [];
    productData: DocumentData[] = [];
    settingData: DocumentData[] = [];
    localData: DocumentData[] = [];
    mediaData: DocumentData[] = [];

    productDataS: DocumentData[] = [];
    private listenOnlineData = false;
    private storageAvailable = true;

    searchActive: boolean = false;
    searchString: string = '';

    doc: any = (doc as any).default;
    constructor(private firebase: AngularFirestore, private storage: StorageService, private g: GService) {
        const code = new URLSearchParams(window.location.search).get('code');
        if (typeof code === 'undefined' || !code) {
            this.g.localPrefs = this.storage.getLocalPrefs();
            this.storage.setLocalPrefs(this.g.localPrefs);
        } else {
            this.g.localPrefs.docCode = code;
            this.storage.setLocalPrefs(this.g.localPrefs);
        }
        // FIREBASE SETUP
        this.firebase.firestore.settings({ cacheSizeBytes: -1 });
        // FOR CACHE DATA SAVE
        this.firebase.firestore.enablePersistence({ synchronizeTabs: true }).catch((err) => {
            if (err) {
                console.error("Error on enable persistence " + err);
            }
        });
        this.firebase.firestore.enableNetwork();
        // COLLEZIONI SEDE
        this.productCollection = this.firebase.firestore.collection("cataloghi").doc(this.doc['i5tlp8']).collection("prodotti");

        //COLLEZIONI SOCIO
        if (this.g.localPrefs.docCode !== 'i5tlp8') {
            this.mediaCollection = this.firebase.firestore.collection("cataloghi").doc(this.doc[this.g.localPrefs.docCode]).collection("media");
            this.settingCollection = this.firebase.firestore.collection("cataloghi").doc(this.doc[this.g.localPrefs.docCode]).collection("impostazioni generali");
            this.productS = this.firebase.firestore.collection("cataloghi").doc(this.doc[this.g.localPrefs.docCode]).collection("prodotti");
        } else {
            this.settingCollection = this.firebase.firestore.collection("cataloghi").doc(this.doc['i5tlp8']).collection("impostazioni generali");
            this.mediaCollection = this.firebase.firestore.collection("cataloghi").doc(this.doc['i5tlp8']).collection("media");
            this.productS = this.firebase.firestore.collection("cataloghi").doc(this.doc['i5tlp8']).collection("prodotti");
        }
    }

    // ############################## DISABLE FIRESTORE NETWORK
    disableNetwork() {
        this.firebase.firestore.disableNetwork();
    }

    // ############################## ENABLE FIRESTORE NETWORK
    enableNetwork() {
        this.firebase.firestore.enableNetwork();
    }

    // SAVE SETTINGS DATA IN GLOBAL SERVICE
    saveSettings() {
        const stringIndex = this.settingData.findIndex((doc) => doc.id === "gestione stringhe");
        this.g.texts = {};
        this.settingData[stringIndex].data().stringhe.forEach((el) => {
            this.g.texts[el.id] = {
                text: el.testo
            };
        });
    }

    saveLocals() {
        this.g.prodFields = {};
        this.localData.forEach((doc) => {
            this.g.prodFields[doc.id] = {
                text: doc.data()
            };
        });
    }

    // DOWNLOAD PRODUCTS DATA FROM SERVER
    downloadProductsDocs(): Observable<DocumentData[]> {
        return new Observable((observer) => {
            let docData: DocumentData[] = [];
            if (typeof this.productS === 'undefined') {
                observer.next([]);
                observer.complete();
            } else {
                this.productS.get({ source: this.g.appPrefs.DBsource }).then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        docData = [];
                        observer.next([]);
                        observer.complete();
                    } else {
                        querySnapshot.forEach((doc) => {
                            docData.push(doc);
                        });
                        console.log(docData);
                        observer.next(docData);
                        observer.complete();
                    }
                }).catch((error) => {
                    observer.error(error);
                    observer.complete();
                });
            }
        });
    }

    // DOWNLOAD SETTINGS DATA FROM SERVER
    downloadSettingsDocs(): Observable<DocumentData[]> {
        return new Observable((observer) => {
            let docData: DocumentData[] = [];
            this.settingCollection.get({ source: this.g.appPrefs.DBsource }).then((querySnapshot) => {
                if (querySnapshot.empty) {
                    docData = [];
                    observer.error(new Error("Settings document is empty"));
                    observer.complete();
                } else {
                    querySnapshot.forEach((doc) => {
                        docData.push(doc);
                    });
                    observer.next(docData);
                    observer.complete();
                }
            }).catch((error) => {
                observer.error(error);
                observer.complete();
            });
        });
    }

    // DOWNLOAD MEDIA DATA FROM SERVER
    downloadMediaDocs(): Observable<DocumentData[]> {
        return new Observable((observer) => {
            let docData: DocumentData[] = [];
            this.mediaCollection.get({ source: this.g.appPrefs.DBsource }).then((querySnapshot) => {
                if (querySnapshot.empty) {
                    docData = [];
                    observer.error(new Error("Media document is empty"));
                    observer.complete();
                } else {
                    querySnapshot.forEach((doc) => {
                        docData.push(doc);
                    });
                    observer.next(docData);
                    observer.complete();
                }
            }).catch((error) => {
                observer.error(error);
                observer.complete();
            });
        });
    }

    // GET ALL PRODUCTS DATA
    getProductsData(): DocumentData[] {
        return this.productData;
    }

    // GET ALL SETTINGS DATA
    getSettigsData(): DocumentData[] {
        return this.settingData;
    }

    // GET ALL PRODUCTS 
    getProducts() {
        const productArray: DocumentData[] = [];
        this.productData.forEach((prod) => {
            productArray.push(prod.data());
        });
        return productArray;
    }

    getProductsLimit(actualIndex) {
        return new Observable((observer) => {
            let limitedDoc: DocumentData[] = [];
            const lang = this.g.localPrefs.language;
            var first = this.productCollection.orderBy('titolo.' + lang).startAfter(actualIndex).limit(10);
            first.get({ source: this.g.appPrefs.DBsource }).then(querySnapshot => {
                if (querySnapshot.empty) {
                    observer.next(limitedDoc);
                    observer.complete();
                } else {
                    querySnapshot.forEach((doc) => {
                        limitedDoc.push(doc.data());
                    });
                    observer.next(limitedDoc);
                    observer.complete();
                }
            }).catch((error) => {
                observer.error(error);
                observer.complete();
            });;
        });
        // }
    }


    searchProduct(search, actualIndex) {
        console.log('IDEX', actualIndex);
        if (search.length > 0) {
            console.log(search.length);
            this.searchActive = true;
            this.searchString = search;
            return new Observable((observer) => {
                let searchDoc: DocumentData[] = [];
                const lang = this.g.localPrefs.language;
                let queryName = this.productCollection.orderBy('titolo.' + lang).where('titolo.' + lang, '>=', search).startAfter(actualIndex).limit(10);
                let queryCode = this.productCollection.orderBy('codice_code').where('codice_code', '>=', search).startAfter(actualIndex).limit(10);
                queryName.get({ source: this.g.appPrefs.DBsource }).then(querySnapshot => {
                    if (querySnapshot.empty) {
                        queryCode.get({ source: this.g.appPrefs.DBsource }).then(querySnapshot2 => {
                            if (querySnapshot2.empty) {
                                observer.next(searchDoc);
                                observer.complete();
                            } else {
                                querySnapshot2.forEach((doc2) => {
                                    searchDoc.push(doc2.data());
                                });

                                observer.next(searchDoc);
                                observer.complete();
                            }
                        }).catch((error) => {
                            observer.error(error);
                            observer.complete();
                        });
                    } else {
                        querySnapshot.forEach((doc) => {
                            searchDoc.push(doc.data());
                        });
                        queryCode.get({ source: this.g.appPrefs.DBsource }).then(querySnapshot2 => {
                            if (querySnapshot2.empty) {
                                observer.next(searchDoc);
                                observer.complete();
                            } else {
                                querySnapshot2.forEach((doc2) => {
                                    searchDoc.push(doc2.data());
                                });
                                observer.next(searchDoc);
                                observer.complete();
                            }
                        }).catch((error) => {
                            observer.error(error);
                            observer.complete();
                        });
                    }
                }).catch((error) => {
                    observer.error(error);
                    observer.complete();
                });
            });
        } else {
            if (this.searchActive) {
                this.searchString = '';
                this.searchActive = false;
                return new Observable((observer) => {
                    this.getProductsLimit(0).subscribe({
                        next: (prods: DocumentData[]) => {
                            observer.next(prods);
                            observer.complete();
                        },
                        error: (err: Error) => {
                            observer.error(err);
                            observer.complete();
                        }
                    });
                });
            } else {
                this.searchString = '';
                this.searchActive = false;
                return new Observable((observer) => {
                    this.getProductsLimit(actualIndex).subscribe({
                        next: (prods: DocumentData[]) => {
                            observer.next(prods);
                        },
                        error: (err: Error) => {
                            observer.error(err);
                            observer.complete();
                        }
                    });
                });
            }
        }
    }


    // GET A SPECIFIC PRODUCT DATA BY CODE
    getProductDataByCode(code): DocumentData {
        return new Observable((observer) => {
            let document: DocumentData[] = [];
            var codeInt = parseInt(code, 10);
            var prod = this.productCollection.where('codice_prodotto', '==', codeInt);
            prod.get({ source: this.g.appPrefs.DBsource }).then(querySnapshot => {
                if (querySnapshot.empty) {
                    console.log('EMPTY');
                    observer.next(document);
                    observer.complete();
                } else {
                    querySnapshot.forEach((doc) => {
                        document.push(doc.data());
                    });
                    observer.next(document[0]);
                    observer.complete();
                }
            }).catch((error) => {
                observer.error(error);
                observer.complete();
            });;
        });
    }

    getWebData() {
        return new Observable((observer) => {
            this.downloadProductsDocs().subscribe({
                next: (products) => {
                    this.productDataS = products;
                    console.log("Download products from " + this.g.appPrefs.DBsource + ": SUCCESS");
                    this.downloadSettingsDocs().subscribe({
                        next: (settings) => {
                            this.settingData = settings;
                            this.saveSettings();
                            console.log("Download settings from " + this.g.appPrefs.DBsource + ": SUCCESS");
                            this.downloadMediaDocs().subscribe({
                                next: (medias) => {
                                    this.mediaData = medias;
                                    console.log("Download medias from " + this.g.appPrefs.DBsource + ": SUCCESS");
                                    //€€€€€€€€€€€€€€
                                    this.getTypesense().subscribe({
                                        next: (data: string) => {
                                            console.log('READ TYPESENSE COLLECTION SUCCESFULLY');
                                            data = data.replace(/(\r\n|\n|\r)/gm, ",");
                                            let tsc = '[';
                                            this.productS.get({ source: this.g.appPrefs.DBsource }).then((querySnapshot) => {
                                                if (querySnapshot.empty) {
                                                } else {
                                                    let index = 0;
                                                    while (index < querySnapshot.size) {
                                                        let doc = querySnapshot.docs[index];
                                                        let d = {
                                                            id: doc.id,
                                                            titolo: doc.data().titolo,
                                                            fromSocio: true
                                                        };
                                                        tsc = tsc + (JSON.stringify(d)) + ',';
                                                        index = index + 1;
                                                    }
                                                }
                                                this.typesenseCollection = JSON.parse(tsc + data + ']');
                                                this.typesenseCollection.sort(function (a, b) {
                                                    var textA = a.titolo.it.toUpperCase();
                                                    var textB = b.titolo.it.toUpperCase();
                                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                                });

                                            });
                                            observer.next();
                                            observer.complete();
                                        },
                                        error: (err: Error) => {
                                            console.error("Download typesense: ERROR", err);
                                            observer.error(err);
                                            observer.complete();
                                        }
                                    });
                                },
                                error: (err: Error) => {
                                    console.error("Download medias from " + this.g.appPrefs.DBsource + ": ERROR", err);
                                    observer.error(err);
                                    observer.complete();
                                }
                            });
                        },
                        error: (err: Error) => {
                            console.error("Download settings from " + this.g.appPrefs.DBsource + ": ERROR", err);
                            observer.error(err);
                            observer.complete();
                        }
                    });

                },
                error: (err: Error) => {
                    console.error("Download products from " + this.g.appPrefs.DBsource + ": ERROR", err);
                    this.firebase.firestore.enableNetwork();
                    observer.error(err);
                    observer.complete();
                }

            });

        });
    }
    // READ IMAGE FROM LOCALSTORAGE
    async readImage(id: string) {
        const mediaIndex = this.mediaData.findIndex((doc) => doc.id === id);
        const mediaDoc = this.mediaData[mediaIndex].data();
        return mediaDoc.url;
    }

    async readImageArray(immArray: any[]) {
        const resImages: any[] = [];
        return new Promise((resolve, reject) => {
            for (let i = 0; i < immArray.length; i++) {
                this.readImage(immArray[i].id).then((imm) => {
                    resImages.push(imm);
                }, (err) => {
                    reject(err);
                });
            }
            resolve(resImages);
        });
    }

    getSettingsImagesData(id: string) {
        const menuIndex = this.settingData.findIndex((doc) => doc.id === id);
        return this.settingData[menuIndex].data();
    }

    /* GET TYPESENSE COLLECTION */
    getTypesense() {
        let client = new Typesense.Client({
            'nodes': [{
                'host': 'cmcode.typesense.ocacloud.it', // For Typesense Cloud use xxx.a1.typesense.net
                'port': '443',      // For Typesense Cloud use 443
                'protocol': 'https'   // For Typesense Cloud use https
            }],
            'apiKey': 'sJRlC2ydbHJ2esXhoGG1UaCxHu3vCgQBNHdiyNzVEtTAONJ9',
            'connectionTimeoutSeconds': 5
        });
        return new Observable((observer) => {
            client.collections('products').documents().export().then(
                (data: string) => {
                    observer.next(data);
                    observer.complete();
                }).catch(reason => {
                    observer.error(reason);
                    observer.complete();
                });
        });
    }

    searchProds(search: string, index: number): Observable<any> {
        return new Observable((observer) => {
            let result = {
                index: index,
                products: []
            };
            let catched = 0;

            // ##################################################################### 21.06.2023 AB
            // while (result.index < this.typesenseCollection.length && catched < 10) {
            //     if (this.typesenseCollection[result.index].titolo[this.g.localPrefs.language].toLowerCase().includes(search.toLowerCase())) {
            //         result.products.push({ id: this.typesenseCollection[result.index].id, fromSocio: this.typesenseCollection[result.index].fromSocio });
            //         catched += 1;
            //     }
            //     result.index += 1;
            // }
            let startWithList = [];

            let containsList = [];

            // filtro e separo prodotti che iniziano con 'search' e quelli che includono 'search'
            this.typesenseCollection.forEach((typesense: any) => {
                if (typesense.titolo[this.g.localPrefs.language].toLowerCase().startsWith(search.toLowerCase())) {
                    startWithList.push({ id: typesense.id, fromSocio: typesense.fromSocio });
                } else if (typesense.titolo[this.g.localPrefs.language].toLowerCase().includes(search.toLowerCase())) {
                    containsList.push({ id: typesense.id, fromSocio: typesense.fromSocio });
                }
            });

            // creo array personalizzato e ordinato
            const myResults = [...startWithList, ...containsList];

            // aggingo 10 prodotti per volta alla lista che poi sarà visibile
            while (result.index < myResults.length && catched < 10) {

                result.products.push(myResults[result.index]);

                catched += 1;
                result.index += 1;
            }
            // #####################################################################

            observer.next(result);
            observer.complete();
        });
    }

    getProductDataByID(code: string): DocumentData {
        return new Observable((observer) => {
            let document: DocumentData;
            this.productCollection.doc(code).get({ source: this.g.appPrefs.DBsource }).then(
                doc => {
                    observer.next(doc.data());
                    observer.complete();
                }).catch((error) => {
                    observer.error(error);
                    observer.complete();
                });;
        });
    }

    getProductDataSByID(code: string): DocumentData {
        const prodIndex = this.productDataS.findIndex((doc) => doc.id === code);
        let prodDoc: DocumentData;
        if (this.productDataS[prodIndex]) {
            prodDoc = this.productDataS[prodIndex].data();
        } else {
            prodDoc = {};
        }
        return prodDoc;
    }
}
