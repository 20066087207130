import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, ModalController, PopoverController } from '@ionic/angular';
import { GService } from 'src/app/services/g.service';
import { DocumentData, DocumentReference } from 'angularfire2/firestore';
import { SearchService } from 'src/app/services/search.service';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { StorageService } from 'src/app/services/storage.service';
import { ProdPageModule } from '../prod.module';
declare let window: any;

@Component({
  selector: 'app-main',
  templateUrl: './prodmain.page.html',
  styleUrls: ['./prodmain.page.scss'],
})
export class ProdMainPage implements OnInit {
  @ViewChild('content', { static: false }) content: any;
  @ViewChild('title', { static: false }) title: any;
  hasScrollbar: Boolean = false;

  prodId: string;
  prodDoc: DocumentData;
  prodUsage: any[] = [];
  localsDocs: DocumentData;
  images: string[] = [];
  imagesTags: string[] = [];
  document: string;
  loading = true;

  constructor(public fire: FirebaseService, private route: ActivatedRoute, private navCtrl: NavController, private router: Router,
    public g: GService, public search: SearchService, public modalController: ModalController,
    private file: File, private opener: FileOpener, public storage: StorageService,
    public popoverCtrl: PopoverController) { }

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.prodId = param.prod;
      this.initProd();
    });
  }

  async initProd() {
    this.prodDoc = this.search.displayedDoc[this.prodId];
    console.log('PDOC', this.prodDoc);
    this.loading = false;
  }

  back() {
    this.search.selectedItem = '';
    this.search.searchTerm = '';
    this.g.selectedProd = '';
    this.search.actualVisible = 0;
    this.navCtrl.navigateBack([
      "/prod",
      {
        outlets: {
          SideRouter: "side",
          MainRouter: "products",
        },
      },
    ]);
  }

  goToHome() {
    this.navCtrl.navigateBack('/welcome');
  }

  imageLoaded(code) {
    code.available = false;
  }
}
