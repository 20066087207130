import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BnNgIdleService } from 'bn-ng-idle';
import { GService } from 'src/app/services/g.service';

@Component({
  selector: 'app-prod',
  templateUrl: './prod.page.html',
  styleUrls: ['./prod.page.scss'],
})
export class ProdPage implements OnInit {

  constructor(public g: GService, private bnIdle: BnNgIdleService, private navCtrl: NavController) { }

  ngOnInit() {
    /*
    this.bnIdle.startWatching(180).subscribe((res) => {
      if (res) {
          this.navCtrl.navigateRoot("/welcome");
      }
    });
    */
  }

}
