import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GService } from 'src/app/services/g.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NavController } from '@ionic/angular';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {

  onInitSlide = true;
  logocat: string;
  sliders: any[] = [];

  constructor(private router: Router, public g: GService, public fire: FirebaseService, private navCtrl: NavController) { }

  async ngOnInit() {
    await this.initSlide();
  }

  goToProd() {
    this.navCtrl.navigateForward(['/prod', {
      outlets: {
        SideRouter: 'side',
        MainRouter: 'products'
      }
    }]);
  }

  async initSlide() {
    this.onInitSlide = true;
    const slidersDocs = this.fire.getSettingsImagesData('gestione immagini');
    await this.fire.readImageArray(slidersDocs.home_slideshow).then((resImgs: string[]) => {
      this.sliders = resImgs;
      this.onInitSlide = false;
    }, (err) => {
      console.error('MAIN GET IMAGE ERROR: ', err);
    });
    this.fire.readImage(slidersDocs.logo_catalogo.id).then((resImg: string) => {
      this.g.logocat = resImg;
      this.onInitSlide = false;
    }, (err) => {
      console.error('MAIN GET IMAGE ERROR: ', err);
    });
  }
}
