import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from './storage.service';
import { NgZone } from '@angular/core';
import { AlertController, NavController } from "@ionic/angular";
import { Router } from "@angular/router";

export interface AppPrefs {
	browser: string;
	catalogUnavailable: boolean;
	bOffline: boolean;
	webMode: boolean;
	width: string;
	height: string;
	DBsource: any;
}

export interface LocalPrefs {
	language: string;
	docCode: string;
}

export interface Language {
	tag: string;
}

export interface Settings {
	id: string;
	text: Language;
}

@Injectable({
	providedIn: "root",
})
export class GService {
	/* TEXT UTILITIES */
	public btnSettings: Settings[] = [];
	public texts: { [key: string]: { text: string } } = {};
	public prodFields: { [key: string]: { text: any } } = {};
	public prevLocation: string;
	public hasSync = false;
	public selectedProd = '';
	public logocat = '';
	public hiddenHome = true;

	public initApp = false;

	public timerActive = false;
	public showRefreshPopup = false;

	/* OTHER GLOBAL UTILITIES */
	public appPrefs: AppPrefs = {} as AppPrefs;
	public localPrefs: LocalPrefs = { language: "it" } as LocalPrefs;
	public catalogLanguages: string[] = ["it"];

	slideOpts = {
		autoplay: {
			disableOnInteraction: false,
			delay: 5000,
			paused: false,
		},
		lazy: false,
		loop: true,
		initialSlide: 0,
		speed: 1000,
		allowTouchMove: false,
		grabCursor: false,
		spaceBetween: 0,
		updateOnImagesReady: true,
		pagination: false,
		zoom: false,
		on: {
			beforeInit() {
				const swiper = this;
				swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
				const overwriteParams = {
					slidesPerView: 1,
					slidesPerColumn: 1,
					slidesPerGroup: 1,
					watchSlidesProgress: true,
					spaceBetween: 0,
					virtualTranslate: true,
				};
				swiper.params = Object.assign(swiper.params, overwriteParams);
				swiper.params = Object.assign(swiper.originalParams, overwriteParams);
			},
			setTranslate() {
				const swiper = this;
				const { slides } = swiper;
				for (let i = 0; i < slides.length; i += 1) {
					const $slideEl = swiper.slides.eq(i);
					const offset$$1 = $slideEl[0].swiperSlideOffset;
					let tx = -offset$$1;
					if (!swiper.params.virtualTranslate) tx -= swiper.translate;
					let ty = 0;
					if (!swiper.isHorizontal()) {
						ty = tx;
						tx = 0;
					}
					const slideOpacity = swiper.params.fadeEffect.crossFade
						? Math.max(1 - Math.abs($slideEl[0].progress), 0)
						: 1 + Math.min(Math.max($slideEl[0].progress, -1), 0);
					$slideEl
						.css({
							opacity: slideOpacity,
						})
						.transform(`translate3d(${tx}px, ${ty}px, 0px)`);
				}
			},
			setTransition(duration) {
				const swiper = this;
				const { slides, $wrapperEl } = swiper;
				slides.transition(duration);
				if (swiper.params.virtualTranslate && duration !== 0) {
					let eventTriggered = false;
					slides.transitionEnd(() => {
						if (eventTriggered) return;
						if (!swiper || swiper.destroyed) return;
						eventTriggered = true;
						swiper.animating = false;
						const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
						for (let i = 0; i < triggerEvents.length; i += 1) {
							$wrapperEl.trigger(triggerEvents[i]);
						}
					});
				}
			},
		}
	};

	/* HTTP REQUEST FIELDS */
	private header = new HttpHeaders();
	private baseUrl = "https://cm.ocacloud.it/php";
	// DEV MODE:
	//private baseUrl = 'http://localhost:3000/php';

	constructor(private http: HttpClient, private storage: StorageService, private zone: NgZone, private alertCtrl: AlertController) { }

	async timer() {
		if (!this.timerActive) {
			var now = new Date(Date.now());
			if (now.getHours() >= 6 && now.getMinutes() >= 0 && now.getSeconds() > 0) {
				var millisTill6 = (new Date(now.getFullYear(), now.getMonth(), now.getDate(), 6, 0, 0, 0).getTime() + 86400000) - now.getTime();
			} else {
				var millisTill6 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 6, 0, 0, 0).getTime() - now.getTime();
			}

			//debug
			// if (now.getHours() >= 12 && now.getMinutes() >= 13 && now.getSeconds() > 0) {
			// 	var millisTill6 = (new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 13, 0, 0).getTime() + 86400000) - now.getTime();
			// } else {
			// 	var millisTill6 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 13, 0, 0).getTime() - now.getTime();
			// }
			

			console.log('ms', millisTill6);
			
			const that = this;
			setTimeout(function () {
				that.refresh();
			}, millisTill6);
			this.timerActive = true;
			
			// const alert = await this.alertCtrl.create({
			// 	cssClass: 'uiUpdateAlert',
			// 	header: 'Aggiornamento App',
			// 	message: 'Attendere la fine dell\'aggiornamento',
			// 	buttons: []
			// });
			// await alert.present();
		}
	}

	async refresh() {
		const alert = await this.alertCtrl.create({
			cssClass: 'uiUpdateAlert',
			header: 'Aggiornamento app in corso',
			message: 'Attendere la fine dell\'aggiornamento',
			buttons: []
		});
		await alert.present();
		setTimeout(function () {
			alert.dismiss();
			window.location.reload();
		}, 5000);
	}
}
