import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { NavController } from "@ionic/angular";
import Keyboard from "simple-keyboard";
import { GService } from "src/app/services/g.service";
import { SearchService } from "src/app/services/search.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
	selector: "app-keyboard",
	templateUrl: "./keyboard.page.html",
	encapsulation: ViewEncapsulation.None,
	styleUrls: ["./keyboard.page.scss", "../../../assets/css/keyboard.scss"],
})
export class KeyboardPage implements OnInit {
	constructor(public g: GService, public search: SearchService, public navCtrl: NavController, public router: Router) { }
	keyboard: Keyboard;

	ngOnInit() { }

	ionViewWillEnter() {
		this.keyboard = new Keyboard("keyboardProd", {
			onChange: (input) => this.onChangeProds(input),
			onKeyPress: (button) => this.onKeyPressProds(button),
		});
	}

	ionViewDidLeave() {
	}

	onInputChange = (event: any) => {
		this.keyboard.setInput(event.target.value);
	};

	handleShift = () => {
		const currentLayout = this.keyboard.options.layoutName;
		const shiftToggle = currentLayout === "default" ? "shift" : "default";
		this.keyboard.setOptions({
			layoutName: shiftToggle,
		});
	};

	// FILTER PRODUCTS
	onKeyPressProds = (button: string) => {
		if (button === "{shift}" || button === "{lock}") {
			this.handleShiftProds();
		} else if (button === "{enter}") {
			/* handle enter button*/
		}
	};

	onInputChangeProds = (event: any) => {
		this.keyboard.setInput(event.target.value);
	};

	handleShiftProds = () => {
		const currentLayout = this.keyboard.options.layoutName;
		const shiftToggle = currentLayout === "default" ? "shift" : "default";
		this.keyboard.setOptions({
			layoutName: shiftToggle,
		});
	};

	onChangeProds = (input: string) => {
		if (this.search.searchTerm) {

		} else {
			this.search.actualVisible = '';
		}
		this.search.searchTerm = input;
	};

	back() {
		this.search.selectedItem = "";
		this.search.searchTerm = "";
		this.search.cancelFilter();
		this.navCtrl.back();
	}

	reset() {
		this.keyboard.setInput('');
		this.search.resetFilter();
	}

	goToHome() {
		this.navCtrl.navigateBack('/welcome');
	}

}
