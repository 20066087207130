import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProdPage } from './prod.page';
import {ProdSidePage} from './prodside/prodside.page';
import {ProdMainPage} from './prodmain/prodmain.page';
import { KeyboardPage } from '../keyboard/keyboard.page';
const routes: Routes = [
  { path: 'prod', component: ProdPage, children: [
    { path: 'side', component: ProdSidePage, outlet: 'SideRouter'},
    { path: 'main/:prod', component: ProdMainPage, outlet: 'MainRouter'},
    { path: 'products', component: KeyboardPage, outlet: 'MainRouter'},
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProdPageRoutingModule {}
