import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomePage } from './pages/welcome/welcome.page';
import { ProdPage } from './pages/prod/prod.page';
import { AppComponent } from './app.component';
import { ConnectPage } from './pages/connect/connect.page';

const routes: Routes = [
  // { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  { path: '', component: AppComponent },
  { path: 'welcome', component: WelcomePage },
  { path: 'prod', component: ProdPage},
  { path: 'keyboard', loadChildren: () => import('./pages/keyboard/keyboard.module').then( m => m.KeyboardPageModule)},
  { path: 'connect', component: ConnectPage }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
