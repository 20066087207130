import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GService } from 'src/app/services/g.service';
import { DocumentData } from 'angularfire2/firestore';
import { createAnimation, NavController } from '@ionic/angular';
import { SearchService } from 'src/app/services/search.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-side',
  templateUrl: './prodside.page.html',
  styleUrls: ['./prodside.page.scss'],
})
export class ProdSidePage implements OnInit {
  id: string;
  prodCode: string;
  products: DocumentData[] = [];
  prodsImg: { [id: string]: { img: string, slides: string[]; available: boolean; } } = {};
  showMobSearch = false;
  internalSlideOpts = {
    loop: false,
    allowTouchMove: true,
    grabCursor: true,
    spaceBetween: 0,
    zoom: false,
  };
  contentReady: boolean = false;
  actualVisible: number = 0;

  constructor(public fire: FirebaseService, private route: ActivatedRoute, public g: GService, private router: Router,
    private navCtrl: NavController, public search: SearchService, @Inject(DOCUMENT) private document: HTMLDocument) { }

  @ViewChild('searchProdBar', { static: false }) searchProdBar: any;

  ngOnInit() { }

  ionViewWillEnter() {
    this.g.selectedProd = '';
    this.initProducts();
  }

  initProducts() {
    this.search.searchProducts();

    // this.search.setFilteredItems().subscribe({
    //   next: (b: boolean) => {
    //     // this.setImages();
    //   },
    //   error: (err) => {
    //     console.log('ERROR :', err.code)
    //     /*
    //     if (err.code === 'unavailable') {
    //       this.g.appPrefs.catalogUnavailable = false;
    //       this.navCtrl.navigateRoot("/connect");
    //     }
    //     */
    //   }
    // });
  }

  async setImages() {
    console.log('SET IMG');
    for (const p of this.search.displayedDoc) {
      if (p.id) { // il prodotto è inserito da sede
        this.prodsImg[p.codice_prodotto] = { img: '', slides: [], available: false };
        const url = 'https://cmcode.ocacloud.it/upload/images/' + p.codice_code + '.jpg';
        this.prodsImg[p.codice_prodotto].img = url;

      } else { // il prodotto è inserito da socio
        await this.readProductsImg(p.immagine, p.codice_prodotto, p);
      }
    }
  }

  onInfiniteScroll(event: any) {
    this.search.searchProducts();
    event.target.complete();

    // if (this.search.displayedDoc.length >= 10) {
    //   console.log('SCROLL');
    //   this.search.addProd = true;
    //   this.initProducts();
    //   event.target.complete();
    // }
  }

  async readProductsImg(prodImg, code, doc) {
    if (prodImg) {
      this.prodsImg[code] = { img: '', slides: [], available: false };
      this.prodsImg[code].slides = [];
      await this.fire.readImage(prodImg.id).then((resImg: string) => {
        this.prodsImg[code].img = resImg;
        this.prodsImg[code].slides.push(resImg);
      }, (err) => {
        console.log('ERROR :', err.code)
        /*
        if (err.code === 'unavailable') {
          this.g.appPrefs.catalogUnavailable = false;
          this.navCtrl.navigateRoot("/connect");
        }
        */
      });
    }
    await this.fire.readImageArray(doc.slides).then((resImgs: string[]) => {
      for (let res of resImgs) {
        this.prodsImg[code].slides.push(res);
      }
    }, (err) => {
      console.log('ERROR :', err.code)
      if (err.code === 'unavailable') {
        /*
        this.g.appPrefs.catalogUnavailable = false;
        this.navCtrl.navigateRoot("/connect");
        */
      }
    });
  }

  navigateProd(prodCode) {
    if (this.search.selectedItem !== prodCode) {
      this.search.selectedItem = prodCode;
      console.log('ITEM', this.search.selectedItem);
      this.g.selectedProd = prodCode;
      this.prodCode = prodCode;
      this.navCtrl.navigateForward(['/prod', {
        outlets: {
          SideRouter: 'side',
          MainRouter: 'main/' + this.prodCode
        }
      }]);
    }
  }

  back() {
    this.g.selectedProd = '';
    this.showMobSearch = false;
    this.search.selectedItem = '';
    this.search.searchTerm = '';
    this.search.cancelFilter();
    this.navCtrl.back();
  }

  navigateBack() {
    this.search.selectedItem = '';
    this.navCtrl.navigateBack([
      "/prod",
      {
        outlets: {
          SideRouter: "side",
          MainRouter: "products",
        },
      },
    ]);
  }

  imageLoaded(code) {
    code.available = true;
  }
}
