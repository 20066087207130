import { Pipe, PipeTransform } from '@angular/core';
import * as translation from '../../assets/translation.json';
import { GService } from '../services/g.service';

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
	constructor(private g: GService) {}
	t: any = (translation as any).default;
	
	transform(value: string): string {
		return this.t[value][this.g.localPrefs.language];
	}
}
